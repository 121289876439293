<template lang="html">
  <div class=" center-horizontal">
    <ul class="flex flex-center-h" role='list'>
      <li>
        <a class="icon-link" href="https://twitter.com/rmx_exchange" target="_blank">
          <TwitterIcon />
        </a>
      </li>
      <li>
        <a class="icon-link" href="https://www.instagram.com/rmx.exchange/" target="_blank">
          <InstagramIcon />
        </a>
      </li>
    </ul>
  </div>

</template>

<script>
import InstagramIcon from '../assets/icons/IconInstagram.vue'
import TwitterIcon from '../assets/icons/IconTwitter.vue'

export default {
  components: {
    TwitterIcon,
    InstagramIcon
  }
}
</script>

<style lang="scss" scoped>
li {
    padding: 0 1em;
}
.icon-link {
    // color: white;
    color: $color-text;
    &:hover {
        color: $color-link;
    }
}
</style>
