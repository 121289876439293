<template>
<div class="center-horizontal newsletter-wrapper">
  <div class="flex flex-horizontal form-wrapper " id="mc_embed_signup">

    <h2>Sign up for our mailing list</h2>

    <form action="https://exchange.us1.list-manage.com/subscribe/post?u=576887058daee9886583fca88&amp;id=e81a5835c6" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>

      <div id="mc_embed_signup_scroll">
        <!-- <label for="mce-EMAIL">Subscribe</label>
      	<input type="email" value="" name="EMAIL" class="email" id="mce-EMAIL" placeholder="email address" required>

          <div class="clear">
            <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button">
          </div>
      </form>
      </div>
       -->


        <input type="email" value="" name="EMAIL" class="email" id="mce-EMAIL" placeholder="email address" required>

        <button class="reset" type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe">Submit</button>
      </div>
    </form>
  </div>
</div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.newsletter-wrapper {
    max-width: 700px;
    margin-bottom: 2rem;
}
.form-wrapper {
    place-content: space-evenly;
    border-radius: 0.5rem;
    align-items: center;
    flex-wrap: wrap;
    // background: rgba($color-text,.5);
    // color: $color-text-inverted;
    // filter: drop-shadow(0px 0px 2px $color-text);
    padding: 1rem;
}
h2 {

    // flex: 1;
    font-size: 1.3rem;
}
label {
    font-size: small;
}
form {
    text-align: right;
    filter: drop-shadow(0px 0px 2px $color-text);
}
input {
    background: $color-text-inverted;
    border: $color-text 1px solid;
    border-radius: 0.5rem 0 0 0.5rem;
    color: $color-text;

    height: 35px;
    padding: 0.25rem 0.25rem 0.25rem 1rem;
    &:focus {
        outline: 0;
        border: white 1px solid;
        color: $color-text-inverted;
        // border-right: none;
        background: $color-text;
    }
}
button.reset {
    border: $color-text 1px solid;
    transition: color 0.3s ease, background 0.3s ease;
    border-radius: 0 0.5rem 0.5rem 0;
    padding: 0.25rem 1em;
    border-left: none;
    height: 35px;
    color: $color-text;
    background: $color-text-inverted;
    &:hover {
        background: $color-text;
        color: $color-text-inverted;
    }
    &:active {
        background: $color-link;
    }
}
</style>
