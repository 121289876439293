<template>
<div id="app">
  <InteractiveLogo />
  <Newsletter />
  <SocialMedia />

</div>
</template>

<script>
import InteractiveLogo from './components/InteractiveLogo.vue'
import SocialMedia from './components/SocialMedia.vue'
import Newsletter from './components/Newsletter.vue'


export default {
  name: 'App',
  components: {
    InteractiveLogo,
    SocialMedia,
    Newsletter
  }
}
</script>

<style lang="scss">
#app {
    width: 100%;
    height: 100vh;
}
</style>
