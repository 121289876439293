<template>
<div class=" center-all">

  <div class="canvas-wrapper outer ">
    <div class="inner">

      <canvas ref="canvas" id="glcanvas" width="100%" height="100%" tabindex="1"></canvas>
    </div>

  </div>
</div>
</template>

<script>
export default {
  name: 'InteractiveLogo',
  mounted() {

    // disable rubberband effect on mobile devices
    // document.getElementById('glcanvas').addEventListener('touchmove', (e) => {
    //   e.preventDefault();
    // }, false);


    function patchInitialized(patch) {
      // You can now access the patch object (patch), register variable watchers and so on
    }

    const patchFinishedLoading = (patch) => {
      // The patch is ready now, all assets have been loaded
      this.$refs.canvas.style.opacity = 1
    }

    document.addEventListener('CABLES.jsLoaded', function(event) {
      CABLES.patch = new CABLES.Patch({
        patch: CABLES.exportedPatch,
        prefixAssetPath: '',
        glCanvasId: 'glcanvas',
        glCanvasResizeToWindow: true,
        onPatchLoaded: patchInitialized,
        onFinishedLoading: patchFinishedLoading,
        canvas: {
          alpha: true,
          premultipliedAlpha: true
        }
      });
    });
  }
}
</script>

<style  lang="scss">
.canvas-wrapper {
    max-width: 768px;
    padding: 2em;
    width: 100%;
    // height: 100vh;
}

.outer {
    position: relative;
    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: (1 / 1) * 100%;
    }
    > .inner {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
canvas {
    display: block;
    position: absolute;
    outline: 0;
    opacity: 0;
    transition: opacity 0.35s ease;
    max-width: 768px;
    height: 100% !important;
    width: 100% !important;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @include mobile {
        pointer-events: none;
    };
}

* {
    /* disable on touch highlights of html elements, especially on mobile! */

}
</style>
